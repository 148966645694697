import React from "react";
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';

// Memory Game Component (Responsive Adjustments)
const MemoryGame = () => {
    const [cards, setCards] = useState([]);
    const [flipped, setFlipped] = useState([]);
    const [matched, setMatched] = useState([]);
    const [moves, setMoves] = useState(0);
    
    const cardIcons = [
        '💻', '🚀', '🌐', '☁️', '🔬', 
        '🤖', '💡', '🎮', '🌈', '📊', 
        '🔧', '🌟'
    ];

    const initializeGame = () => {
        const shuffledIcons = [...cardIcons, ...cardIcons]
            .sort(() => Math.random() - 0.5)
            .map((icon, index) => ({ id: index, icon, flipped: false }));
        
        setCards(shuffledIcons);
        setFlipped([]);
        setMatched([]);
        setMoves(0);
    };

    useEffect(() => {
        initializeGame();
    },[]);

    const handleCardFlip = (card) => {
        if (
            flipped.length === 2 || 
            matched.includes(card.id) || 
            flipped.some(f => f.id === card.id)
        ) return;

        const newFlipped = [...flipped, card];
        setFlipped(newFlipped);
        setMoves(moves + 1);

        if (newFlipped.length === 2) {
            if (newFlipped[0].icon === newFlipped[1].icon) {
                setMatched([...matched, newFlipped[0].id, newFlipped[1].id]);
                setFlipped([]);
            } else {
                setTimeout(() => setFlipped([]), 1000);
            }
        }
    };

    return (  
        <div className="bg-white/10 rounded-2xl p-4 sm:p-6 text-center w-full">
            <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-white"> 
                Memory Game Challenge 
            </h2>  
            <p className="text-gray-300 mb-2 sm:mb-4">Moves: {moves}</p>  
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-2 justify-items-center">  
                {cards.map((card) => (  
                    <motion.div   
                        key={card.id}   
                        className={`w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center 
                        rounded-lg cursor-pointer text-xl sm:text-3xl   
                        ${matched.includes(card.id) ? 'bg-green-500/50' : 'bg-white/20'}   
                        ${flipped.some(f => f.id === card.id) ? 'bg-purple-500/50' : ''}`}   
                        onClick={() => handleCardFlip(card)}   
                        whileHover={{ scale: 1.1 }}   
                        whileTap={{ scale: 0.9 }}   
                    >  
                        {matched.includes(card.id) || flipped.some(f => f.id === card.id) ? card.icon : '❓'}  
                    </motion.div>  
                ))}  
            </div>  
            <button   
                onClick={initializeGame}   
                className="mt-2 sm:mt-4 bg-purple-600 text-white px-3 py-1 sm:px-4 sm:py-2 
                rounded-full hover:bg-purple-700 text-sm sm:text-base"  
            >  
                Reset Game  
            </button>  
        </div>  
    );
};

    export default MemoryGame;
